/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // Lazy load all the images with class "lazy"
        new LazyLoad({
          elements_selector: ".lazy"
        });
        //load objectFitImages poly-fill on all images.
        objectFitImages();

        // `position:sticky` polyfill
        // Add all your to-be-stickied classes here
        var elements = $('.sticky');
        Stickyfill.add(elements);

        // listener for team member (and potentially other) carousels
        if( $('.mmccorpcarusel').length ){
          carouseljs.init();
        }


        // listener for map applicatoin
        if( $('.properties-map-application-block').length ){
          propertiesjs.init();
        }

        // listener for all cta slider swipers
        ctasliderjs.init('.ctablock__carousel');

        // Prevent Scroll on Hamburger Nav
        $('button.header__hamburger').click(function() {
          if ($('button.header__hamburger').hasClass('is-active')) {
            $('button.header__hamburger').removeClass('is-active');
            $('nav.mobileNav').removeClass('is-active');
            $('body').removeClass('ham-active');
          } else {
            $('button.header__hamburger').addClass('is-active');
            $('nav.mobileNav').addClass('is-active');
            $('body').addClass('ham-active');
          }
        });

        // Contact Form Background Color and Unwrap Paragraph Parent
        $('div.wpcf7').prepend('<div></div>');
        $('div.wpcf7 div').first().addClass('form_section__bg absolute w-full h-1/2 left-0 top-0 background-offset-light opacity-20');

        $('form.wpcf7-form label').unwrap();
        $('form.wpcf7-form input[type=submit]').unwrap();

        // Posts Swiper
        new Swiper(".posts_section__swiper", {
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });


        $('.copy_section__text a').addClass('site-btn');
        $('.hero_section__copy a').addClass('site-btn--clear');


        $('.wp-block-gallery .blocks-gallery-image figcaption, .wp-block-gallery .blocks-gallery-item figcaption, .blocks-gallery-grid .blocks-gallery-image figcaption,.blocks-gallery-grid .blocks-gallery-item figcaption').wrapInner('<div class="color-white font-secondary text-xl"></div>').prepend('<div class="background-primary absolute w-full h-full left-0 bottom-0 opacity-0"></div>');



      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
