var propertiesjs = (function($){

  // holds objects used in this script
  __p$ = {
    mapapp:           $('#mapapp'),
    loading:          $('.mapapp__loading'),
    markupContainer:  $('#propcardmarkup'),
    search:           $('#filter_search'),
    selects:          $('.mapfilters__select'),
    reset:            $('#mapappreset'),
    hlPlaceholder:    $('#hlPlaceholder'),
    changed:          $('#changedindicator'),
    header:           $('.header'),
    window:           $(window),
    mapDisplay:       $('.mapdisplay__map'),
    mapNone:          $('.mapdisplay__none'),
  };


  // holds global variables of this script
  __p = {
    cardMarkup: "",
    allPropertyKeys: [],
    allQueryValues: [],
    st: 0,
    firstVisit: true,
  };


  var __map;
  var __activeMarkers;
  var __markerClusterer;


  var _unsetAllMarkers = function(){
    if( typeof __markerClusterer !== 'undefined' ){
      __markerClusterer.setMap(null);
    }
    if( typeof __activeMarkers !== 'undefined' && __activeMarkers.length ){
      for (var i = 0; i < __activeMarkers.length; i++ ) {
        __activeMarkers[i].setMap(null);
      }
      __activeMarker = [];
    }
  };



  var _initMap = function () {

    // catches empty map styles
    var mapStyles = [];
    if( mmc_map_config_data.style !== undefined ){
      mapStyles = JSON.parse( mmc_map_config_data.style );
    }


    // inits the map
    __map = new google.maps.Map(document.getElementById("map"), {
      zoom: 13,
      streetViewControl: false,
      styles: mapStyles,
      maxZoom: 15,
      center: {
        lat: 0,
        lng: 0
      }
    });

  };



  var _sortProperties =function(a, b){
    var order1 = Number( $(a).attr("data-order") );
    var order2 = Number( $(b).attr("data-order") );
    return (order1 < order2) ? -1 : (order1 > order2) ? 1 : 0;
  };



  /**
   * Makes an Array of all existing Properties in the correct order
   */
  var _createPropertiesArray = function(){
    for(var props in mmc_properties_app_data){
      __p.allPropertyKeys.push( props );
    }
  };



  /**
   * Fetches the Property Card Markup
   * and removes its container from the DOM
   */
  var _getPropertyCardMarkup = function(){
    __p.cardMarkup = __p$.markupContainer.html();
    __p$.markupContainer.remove();
  };



  /**
   *
   * Function will compare a min and a max from the
   * mmc_properties_app_data array against a range of 2 values
   *
   * @param   {string}  attr  the actual attribute from the search (ie: '1000-2000')
   * @param   {string}  type  the name of the thing we are checking (ie: 'price')
   * @return  {array}   array of all the IDs that match
   */
  var _findRangeInProp = function( range, type, lookThroughThese ){
    var matchingIDs = [];

    if( range !== 'all' ){
      var rangeA = range.split('-');

      // if not, loop through each property and see if it matches
      for(var i = 0; i < lookThroughThese.length; i++){
        curItem = mmc_properties_app_data[ lookThroughThese[i] ];

        if( typeof curItem[type] !== 'undefined'){
          curMin = curItem[type].min;
          curMax = curItem[type].max;

          if( curMin <= rangeA[0] && curMax >= rangeA[0] || curMin <= rangeA[1] && curMax >= rangeA[1]  ){
            matchingIDs.push( curItem.id );
          }
        }
      }
    }

    // in 'all' case
    else{
      for (var allKey in mmc_properties_app_data){
        matchingIDs.push( mmc_properties_app_data[allKey].id  );
      }
    }

    // returns all the matches
    return matchingIDs;

  };



  /**
   * Used by the Search Field to return an array
   * of properties matching the desired input
   */
  var _findValueInProp = function( searchString, type, lookThroughThese ){
    var matchingIDs = [];

    if( searchString !== 'all' ){

      // loop through each property, find the type key (aka bathrooms, bedrooms, etc)
      for(var i = 0; i < lookThroughThese.length; i++){
        curItem = mmc_properties_app_data[ lookThroughThese[i] ];

        // makes sure it exists for this property
        if( typeof curItem[type] !== 'undefined'){

          // and see if the searchstring is included somewhere in that key
          if( curItem[type].toLowerCase().includes( searchString.toLowerCase() ) ){
            matchingIDs.push( curItem.id );
          }
        }
      }
    }

    // in 'all' case
    else{
      for (var allKey in mmc_properties_app_data){
        matchingIDs.push( mmc_properties_app_data[allKey].id  );
      }
    }

    // returns all the matches
    return matchingIDs;

  };



  /**
   * Used by the Search Field to return an array
   * of properties matching the desired input
   */
  var _findSearchstringInProp = function( searchString, lookThroughThese ){
    var matchingIDs = [];

    // loops through each property
    for(var i = 0; i < lookThroughThese.length; i++){
      currentItem = mmc_properties_app_data[ lookThroughThese[i] ];

      // case-insensitively compares the title,
      // address (which contains street, city and zip,
      // long-spelling of state,
      // and if match was found, save ID
      if(
        currentItem.title.toLowerCase().includes(searchString.toLowerCase()) ||
        currentItem.address.address.toLowerCase().includes(searchString.toLowerCase()) ||
        currentItem.address.state.toLowerCase().includes(searchString.toLowerCase())
      ){
        matchingIDs.push( currentItem.id );
      }
    }

    // returns all the matches
    return matchingIDs;

  };



  /**
   * Creates the markup for a completed property card from a passed array of data
   */
  var _printPropertyCard = function( propertyArray ){
    var data = "";

    // from each property in the array
    for (var propArKey in propertyArray){
      var currentValue = propertyArray[propArKey];

      // if the value is an object, parse it further
      if( typeof currentValue === 'object' && currentValue !== null ){
        var namePartOne = propArKey;

        // loops through the array, and constructs the data attribute.
        for (var subArrayKey in currentValue){
          var namePartTwo = subArrayKey;

          // example:     beds        min          =   1
          data += 'data-'+namePartOne+namePartTwo+'="'+currentValue[subArrayKey]+'" ';
        }

      }
      else{
        // not not, create a data-attribute and value pair
        data += 'data-'+propArKey+'="'+propertyArray[propArKey]+'" ';
      }
    }

    // makes sure we are getting a valid about-text
    var aboutText = '';
    if( propertyArray.blurp != null ){
       aboutText = propertyArray.blurp;
    }

    // fetches markup from the property Card
    var markup = __p.cardMarkup;

    // replaces placehoders with the data from Array
    markup = markup.replace("[[[data]]]", data);
    markup = markup.replace("[[[title]]]", propertyArray.title);
    markup = markup.replace("[[[url]]]", propertyArray.url);
    markup = markup.replace("[[[street]]]", propertyArray.address.street_number + ' ' + propertyArray.address.street_name);
    markup = markup.replace("[[[city]]]", propertyArray.address.city);
    markup = markup.replace("[[[state]]]", propertyArray.address.state_short);
    markup = markup.replace("[[[zip]]]", propertyArray.address.post_code);
    markup = markup.replace("[[[website]]]", propertyArray.website);
    markup = markup.replace("[[[websitebutton]]]", propertyArray.webbutton);
    markup = markup.replace("[[[image]]]", propertyArray.image);
    markup = markup.replace("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7", propertyArray.image);
    markup = markup.replace("[[[about]]]", aboutText);

    return markup;
  };



  /**
   * After Cards have been created, make sure no invalid data is present
   */
  var _validatePropertyCards = function(){
    var $allCards = $('.propertycard');

    // hides all buttons for website links
    $allCards.each(function(){
      var $cur = $(this);
      var currentHref = $cur.find('.propertycard__extlink').attr('href');
      if( currentHref == 'null' || !currentHref ){
        $cur.find('.propertycard__cta').remove();
      }
    });
  };


  /**
   * check if mapapp is in view, and if not, indicate little scrollspy
   */
  var _indicateChange = function(){
    if( !__p.firstVisit ){
      if( __p$.window.outerHeight(true) + __p.st < __p$.mapapp.offset().top ){
        __p$.changed.fadeIn();
      }
    }
  };



  /**
   * Makes property cards for all the IDs of properties passed
   *
   * @param  {aray}  array  an array of the IDs for which to draw cards
   */
  var _populatePropertyCards = function( array ){

    // clears all previous cards
    __p$.mapapp.html('');

    // loops through the passed
    for(var i = 0; i < array.length; i++){
      var curItem = mmc_properties_app_data[array[i]];
      __p$.mapapp.append( _printPropertyCard(curItem) );
    }

    $('.propertycard').sort(_sortProperties).prependTo('#mapapp');
    __p$.hlPlaceholder.html( array.length );

    // validates each newly created card
    _validatePropertyCards();

    // checks/shows scroll indicator
    if( mmc_map_config_data.scrollto ){
      _indicateChange();
    }
  };



  var __markerClick = function(){
    _populatePropertyCards( [this.markerId] );
    window.scrollTo({
      top: (__p$.mapapp.offset().top - __p$.header.outerHeight(true)) ,
      behavior: 'smooth',
    });
  };



  /**
   * Draws a certain set of markers on the initialized map
   *
   * @param  {array}  whichMarkers
   */
  var _drawOnMap = function( whichMarkers ){

    _unsetAllMarkers();

    // initial bounds (what centers the view on only the icons)
    var bounds = new google.maps.LatLngBounds();

    // clears the active markers array
    __activeMarkers = [];

    var counter = 0;
    for(var j = 0; j < whichMarkers.length; j++){

      // finds the corresponding property in mmc_properties_app_data
      var curToBeMarkedProp = mmc_properties_app_data[whichMarkers[j]];

      // prepares the defautl marker image
      var image = {
        url: mmc_map_config_data.marker.image,
        scaledSize : new google.maps.Size( parseInt(mmc_map_config_data.marker.width), parseInt(mmc_map_config_data.marker.width) ),
      };

      // checks if there is a custom image associated with the property
      if( curToBeMarkedProp.icon ){
        image = {
          url: curToBeMarkedProp.icon.image,
          scaledSize : new google.maps.Size( parseInt(curToBeMarkedProp.icon.width), parseInt(curToBeMarkedProp.icon.width) ),
        };
      }

      // creates the marker
      var curMarker =  new google.maps.Marker({
        position: new google.maps.LatLng(parseFloat(curToBeMarkedProp.address.lat), parseFloat(curToBeMarkedProp.address.lng)),
        // label: curToBeMarkedProp.title,
        icon: image,
        markerId: curToBeMarkedProp.id,
        optimized: false,
        map: __map,
        animation: google.maps.Animation.DROP,
      });

      // click listener for the marker!
      curMarker.addListener("click", __markerClick);

      // adds the marker's coordinates to the bounds
      bounds.extend(new google.maps.LatLng(parseFloat(curToBeMarkedProp.address.lat), parseFloat(curToBeMarkedProp.address.lng)));

      // add to markers array
      __activeMarkers.push(curMarker);

      counter++;
    } // end forloop

    // fits the map-view and -zoom
    __map.fitBounds(bounds);

    // if clustering was enabled,
    // Add a marker clusterer to manage the markers, if desired
    if( mmc_map_config_data.cluster ){
      var clusterImagePath = "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m";
      if( mmc_map_config_data.cluster_image ){
        clusterImagePath = mmc_map_config_data.cluster_image;
      }
      __markerClusterer = new MarkerClusterer(__map, __activeMarkers, {
        imagePath: clusterImagePath
      });

      // if a cluster is clicked, refine the card display, too
      google.maps.event.addListener(__markerClusterer, 'clusterclick', function(cluster){
        var idsInThisCluster = [];
        var allMarkersInCluster = cluster.getMarkers();

        for(var c = 0; c < allMarkersInCluster.length; c++){
          idsInThisCluster.push(allMarkersInCluster[c].markerId);
        }

        _populatePropertyCards( idsInThisCluster );

      });


    }
  }; // end _drawOnMap



  /**
   * Checks if we found any properties, and change the UI accordingly
   *
   * @param  {array}  input array of properties
   */
  var _checkZeroInstance = function( input ){
    if( input.length === 0 ){
      __p$.mapDisplay.addClass('transparent-map');
      __p$.changed.addClass('transparent-map');
      __p$.mapNone.show();
    }else{
      __p$.mapNone.hide();
      __p$.mapDisplay.removeClass('transparent-map');
      __p$.changed.removeClass('transparent-map');
    }
  };



  /**
   * Function reads and parses the URL fragment/hash
   * into the internal store for query values. (__p.allQueryValues)
   * This internal store is what will be used to drive the logic in filtering properties
   */
  var _parseUrlHash = function(){
    __p.allQueryValues = [];

    // reads the URL hash and splits it by the /
    var valuesFromHash = window.location.hash.split('/');

    var allQuerysPresentInTheUrl = [];

    for(var i = 1; i < valuesFromHash.length; i++){

      // splits each into query and value (something=else)
      var current  = valuesFromHash[i].split('=');
      var curQuery = current[0];
      var curValue = decodeURIComponent(current[1]);

      // updates or appends it to the array
      if( curQuery ){
        __p.allQueryValues[curQuery] = curValue;
        allQuerysPresentInTheUrl.push( curQuery );
      }

    }
  };



  /**
   * Updates internal Query Values and creates a new URL hash.
   *
   * @param query     {string}  example: "SEARCH=something"
   * @param parameter {string}  example: "search=SOMETHING"
   * @return {false}
   */
  var _updateUrlHash = function( query, parameter ){

    // updates the values
    __p.allQueryValues[query] = parameter;

    // new hash-string
    var hashString = '/';

    // makes a new hash string from the internall stored queries
    for (var propKey in __p.allQueryValues){
      if( propKey && propKey !== '' && __p.allQueryValues[propKey] !== 'all' ){
        hashString += propKey + '=' + __p.allQueryValues[propKey] + '/';
      }
    }

    // remove trailing `/`
    hashString = hashString.replace(/\/$/, "");

    // writes out the hash string
    window.location.hash = hashString;

  };



  /**
   * Function called every time the URL hash changes.
   *
   * This could be due to any number of reasons:
   *  - a Search-field input
   *  - a dropdown change
   *  - a history event
   *  - a new page load
   *  - someone just typing in the URL
   *
   * This function runs the logic which determines
   * which properties are to be displayed
   *
   */
  var _updateQuery = function(){

    // array to hold the IDs of any matching properties.
    // Start with ALL properties, we'll take away from these as we refine
    var currentlyMatchingProperties = __p.allPropertyKeys;


    // sets all dropdowns to default, will re-set to their proper values when looping through list
    __p$.selects.each(function(){
      $(this).val( $(this).find('option:first').val() );
    });


    if( $.isEmptyObject( __p.allQueryValues ) ){
      //  if the URL is empty
    }
    else{
      /**
       * Loops through all the available queries.
       * On each loop, we are passing the currentlyMatchingProperties to a search/find function,
       * and are only keeping the ones that still match. Those will be drawn at the end
       */
      for (var qKey in __p.allQueryValues){
        var curQuery = __p.allQueryValues[qKey];


        /* * if a SEARCH QUERY was found * */
        if( qKey === 'search' ){

          // finds only those properties from the currentlyMatchingProperties array,
          // that contain that search-string
          currentlyMatchingProperties = _findSearchstringInProp( curQuery, currentlyMatchingProperties );

          // updates the search field (in case different)
          if( __p$.search.val() !== curQuery ){ __p$.search.val( curQuery ); }
        }


        /* * if not, assume DROPDOWN QUERY * */
        else{
          var attrResult;

          // addresses the select & checks if single-value or range comparision
          var $attSelect = $('[data-selecttype="'+qKey+'"]');
          var datatype = $attSelect.attr('data-datatype');

          // runs the apprpriate function, passing both value&key, and currentlyMatchingProperties
          if( datatype === 'range' ){
            attrResult = _findRangeInProp( curQuery, qKey, currentlyMatchingProperties );
          }else{
            attrResult = _findValueInProp( curQuery, qKey, currentlyMatchingProperties );
          }

          // now currentlyMatchingProperties only contains IDs of those that still match after the query
          currentlyMatchingProperties = attrResult;

          // updates the drop-down (in case different)
          if( $attSelect.val() !== curQuery ){ $attSelect.val( curQuery ); }
        }  // end dropdown query


        /* * also reset any dropdowns not part of the current query * */
        $('[data-selecttype="'+qKey+'"]').val(curQuery);

      } // end for loop

    } // end else


    /* * (re) prints all the property cards * */
    _populatePropertyCards( currentlyMatchingProperties );


    /* * Draws all the filtered properties on the map * */
    _drawOnMap( currentlyMatchingProperties );


    /* * Check for Zero Instance * */
    _checkZeroInstance( currentlyMatchingProperties );

  };



  /**
   * Sets up listener for all selects
   */
  var _selectsInit = function(){
    __p$.selects.on('change', function(){
      var $this = $(this);

      // updates the Hash with datatype=value, i.e. bedrooms=2
      _updateUrlHash($this.attr('data-selecttype'), $this.val());
    });
  };



  /**
   * Sets up Listener for search field
   */
  var _searchInit = function(){
    __p$.search.on('keyup', function(){

      // fetches the value from the field
      typedValue = __p$.search.val();

      // when more than 3 chars have been typed
      if( typedValue.length > 2 ){
        // updated the hash
        // waits a little, then calls the update function with the parsed search term
        setTimeout(function(){
          _updateUrlHash('search', typedValue);
        }, 750);

      }

      // if field is cleared, show all
      if( !typedValue.length ){
        _populatePropertyCards( __p.allPropertyKeys );
      }

    });
  };



  var _resetInit = function(){
    __p$.reset.on('click', function(){
      // this hack is on purpose, please leave
      if( window.location.hash === '#/' ){
        window.location.hash = '#';
      }else{
        window.location.hash = '#/';
      }
    });
  };



  var _hashChangeListen = function(){

    // initial run
    _parseUrlHash(); // prepares for _updateQuery() function call
    _updateQuery();  // runs the logic determining which properties to display

    // listens for hash change
    window.addEventListener("hashchange", function(){
      _parseUrlHash();
      _updateQuery();
    });
  };


  /**
   * Related to scroll-indicator functionality
   */
  var _scrollListener = function(){

    // listens for scroll and updates proper
    __p$.window.on('scroll', function(){
      // __p.st = __p$.window.scrollTop();
      __p.st = window.scrollY;

      if( __p$.window.outerHeight(true) + __p.st > __p$.mapapp.offset().top ){
        __p$.changed.fadeOut();
      }
    });

    // click-listener for scroll button
    __p$.changed.on('click', function(){

      window.scrollTo({
        top: (__p$.mapapp.offset().top - __p$.header.outerHeight(true)) ,
        behavior: 'smooth',
      });

    });

  };



  var init = function(){

    _initMap();

    _createPropertiesArray();

    _getPropertyCardMarkup();

    _searchInit();

    _selectsInit();

    _resetInit();

    _hashChangeListen(); // also runs the initial draw

    if( mmc_map_config_data.scrollto ){
      _scrollListener();
      __p.firstVisit = false;
    }

  };



 /**
  * Public Methods
  */
  return{
    init: init,
  };

})(jQuery);