var ctasliderjs = (function($){

  // variable to determined if (any) pause button listener has been started.
  // We only need one (which handles all pause buttons),
  // and we don't want duplicate events.
  var _pauseButtonListenerRunning = false;

  // object that will contain all instances of all swipers
  var imageSwiperConainer = {};


  var _changeCaptionCopy = function(id, target){
    var caption = $('#'+target+' [data-slidecaptioncounter="'+id+'"]').html();
    $('#captionfor'+target).html(caption);
  };


  /**
   * Function will initiate a swiper for each passed selector string
   * @param  {[string]} selectorstring  The jQuery selector string
   */
  var _swiperinit = function(selectorstring, passedspeed){

    // console.log('init!!');

    // grabs and boolifies the autoplay setting from the data-attribute
    var autoplaysetting = $(selectorstring).attr('data-carautoplay') || 'true';
    if( autoplaysetting === 'true'){ autoplaysetting = true; }else{ autoplaysetting = false; }

    // grabs the carousel ID from the data-attribute
    var carousel_id = $(selectorstring).attr('data-carouselid');

    // creates a new entry into the swiperContainer object, containing all the swipers
    imageSwiperConainer[carousel_id] = new Swiper (selectorstring, {
      // centeredSlides: true,
      // spaceBetween: 24,
      // autoHeight: true,
      loop: true,
      slidesPerView: 'auto',
      grabCursor: true,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      centerInsufficientSlides: true,

      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
      },
      pagination: {
        el: '.ctaslider-'+carousel_id+'-pagination',
        bulletElement: 'button',
        clickable: true,
      },
      navigation: {
        nextEl: '.ctaslider-'+carousel_id+'-next',
        prevEl: '.ctaslider-'+carousel_id+'-prev',
      },
    }); // end `new Swiper` init

  }; // end _swiperinit


  /**
   * Handles all the pause buttons for all the swipers
   */
  var _pauseButtonHandler = function(){

    // if the pause button listener is not yet running,
    // start, and set that variable to true
    // ensures we only get one listener in caes of multiple carousels
    if( !_pauseButtonListenerRunning ){

      _pauseButtonListenerRunning = true;

      // when any of the pause buttons is clicked
      $('.ctaslider__pause').on('click', function(){

        // find which slider to address
        var pausebuttonfor = $(this).attr('data-pausebuttonfor');

        // and in playing state
        if( $(this).hasClass('this--playing') ){

          // pause the slider
           imageSwiperConainer[pausebuttonfor].autoplay.stop();
        }else{

          // it not, start the slider
          imageSwiperConainer[pausebuttonfor].autoplay.start();
        }

        // flip the classes
        $(this).toggleClass('this--playing');
        $(this).toggleClass('this--paused');

      });
    }
  };


  /**
   * PUBLIC METHOD
   * Init Method called in main.js.
   */
  var init = function(selectorstring){

    // checks to make sure the selector is a carousel and not a static image
    if($(selectorstring).hasClass('ctablock__carousel') ){
      _swiperinit(selectorstring);
    }

    // runs (checks) the pausebutton listner
    _pauseButtonHandler();
  };


 /**
  * Public Methods
  */
  return{
    init: init,
  };

})(jQuery);